import React from 'react';
import '../styles/TrustedBySection.css';
import Slider from 'react-slick';
import client1 from '../assets/customers/nissan.png'; 
import client2 from '../assets/customers/bmw.png';
import client4 from '../assets/customers/cleber.png';
import client7 from '../assets/customers/omoda.png';
import client8 from '../assets/customers/salfa.png';
import client9 from '../assets/customers/grupo-rivero.png';
import client3 from '../assets/customers/gocar.png';

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

function TrustedBySection() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 5000,
    slidesToShow: 5, // Logos visibles en pantallas grandes
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0,
    cssEase: "linear",
    arrows: false,
    centerMode: true, // Activa el modo de centrado para manejar el espacio
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3, // Ajuste en tablet
          centerMode: true, // Asegura que los logos se centren en tablet
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2, // Ajuste en móviles medianos
          centerMode: true,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1, // Solo un logo visible en móviles pequeños
          centerMode: true,
        }
      }
    ]
  };

  return (
    <div className="trusted-by-section">
      <h2>Trusted by</h2>
      <Slider {...settings} className="logo-slider">
        <div className="slide"><img src={client1} alt="Client 1" className="client-logo" /></div>
        <div className="slide"><img src={client2} alt="Client 2" className="client-logo" /></div>
        <div className="slide"><img src={client3} alt="Client 3" className="client-logo" /></div>
        <div className="slide"><img src={client4} alt="Client 4" className="client-logo" /></div>
        <div className="slide"><img src={client7} alt="Client 2" className="client-logo" /></div>
        <div className="slide"><img src={client8} alt="Client 3" className="client-logo" /></div>
        <div className="slide"><img src={client9} alt="Client 4" className="client-logo" /></div>
      </Slider>
    </div>
  );
}

export default TrustedBySection;