import React from 'react';
import { useTranslation } from 'react-i18next';
import '../styles/PrivacyPolicy.css';

function PrivacyPolicy() {
  const { t } = useTranslation();

  return (
    <div className="privacy-policy">
      <h1>{t('privacyPolicy.title')}</h1>
      <p>{t('privacyPolicy.lastUpdated')}</p>
      
      <h2>{t('privacyPolicy.section1.title')}</h2>
      <p>{t('privacyPolicy.section1.text')}</p>
      
      <h2>{t('privacyPolicy.section2.title')}</h2>
      <p>{t('privacyPolicy.section2.text1')}</p>
      <p>{t('privacyPolicy.section2.text2')}</p>
      <p>{t('privacyPolicy.section2.text3')}</p>
      
      <h2>{t('privacyPolicy.section3.title')}</h2>
      <p>{t('privacyPolicy.section3.text')}</p>
      
      <h2>{t('privacyPolicy.section4.title')}</h2>
      <p>{t('privacyPolicy.section4.text')}</p>
      
      <h2>{t('privacyPolicy.section5.title')}</h2>
      <p>{t('privacyPolicy.section5.text')}</p>
      
      <h2>{t('privacyPolicy.section6.title')}</h2>
      <p>{t('privacyPolicy.section6.text')}</p>
      
      <h2>{t('privacyPolicy.section7.title')}</h2>
      <p>{t('privacyPolicy.section7.text')}</p>
      
      <h2>{t('privacyPolicy.section8.title')}</h2>
      <p>{t('privacyPolicy.section8.text')}</p>
      
      <h2>{t('privacyPolicy.section9.title')}</h2>
      <p>{t('privacyPolicy.section9.text')}</p>
      
      <h2>{t('privacyPolicy.section10.title')}</h2>
      <p>{t('privacyPolicy.section10.text')}</p>
      
      <h2>{t('privacyPolicy.section11.title')}</h2>
      <p>{t('privacyPolicy.section11.text')}</p>
      
      <h2>{t('privacyPolicy.section12.title')}</h2>
      <p>{t('privacyPolicy.section12.text')}</p>
    </div>
  );
}

export default PrivacyPolicy;
