import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import '../styles/BlogSection.css';
// import usePosts from '../hooks/usePost'; // Comentamos la carga de artículos

function BlogSection() {
  const { t } = useTranslation();
  // const { posts } = usePosts();  // Comentamos la carga de artículos
  // console.log('posts', posts);

  return (
    <section className="blog-section">
      <h2>{t('blog.featuredArticles')}</h2>
      <div className="blog-posts">
        {/* Aquí es donde mostraba los posts si existían */}
        <div className="blog-info">
          <p>{t('blog.workingOnCases')}</p>
          <p>{t('blog.privacyNotice')}</p>
          <p>{t('blog.privateDemos')}</p>
        </div>
      </div>
    </section>
  );
}

export default BlogSection;
