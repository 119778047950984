import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import '../styles/Footer.css';
import logo from '../assets/Logo-white.png';
import linkedinIcon from '../assets/linkedin-icon.png';

function Footer() {
  const { t } = useTranslation();

  return (
    <div className="footer-wrapper">
      <footer className="footer">
        <div className="footer-columns">
          <div className="footer-column">
            <img src={logo} alt="Company Logo" className="footer-logo" />
            <p className="footer-slogan">{t('footer.slogan')}</p>
          </div>
          <div className="footer-column">
            <h4>{t('footer.resourcesTitle')}</h4>
            <ul>
              <li><Link to="/blog">{t('footer.blog')}</Link></li>
              <li><Link to="/contact">{t('footer.contact')}</Link></li>
            </ul>
          </div>
          <div className="footer-column">
            <h4>{t('footer.solutionsTitle')}</h4>
            <ul>
              {/* <li><Link to="/b2c-solution">{t('footer.b2cSolution')}</Link></li> */}
              <li><Link to="/">{t('footer.b2bSolution')}</Link></li>
            </ul>
          </div>
          <div className="footer-column">
            <h4>{t('footer.legalTitle')}</h4>
            <ul>
              <li><Link to="/privacy-policy">{t('footer.privacyPolicy')}</Link></li>
              <li><Link to="/contact">{t('footer.contact')}</Link></li>
            </ul>
          </div>
        </div>
        <div className="footer-bottom">
          <p>© 2025 Krino LLC</p>
          <a href="https://www.linkedin.com/company/35621708/" target="_blank" rel="noopener noreferrer">
            <img src={linkedinIcon} alt="LinkedIn" className="linkedin-icon" />
          </a>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
