import React from 'react';
import HeroSection from '../components/HeroSection';
import Features from '../components/Features';
import TrackRecord from '../components/TrackRecord';
import TrustedBySection from '../components/TrustedBySection';
import GetStartedForm from './GetStarted';
import BlogSection from './BlogSection';

function Home() {
  return (
    <div>
      <HeroSection />
      <TrustedBySection /> {/* Placing the "Trusted by" section after Features */}
      <Features />
      <TrackRecord />
      <GetStartedForm />
      {/* <BlogSection /> */}
    </div>
  );
}

export default Home;
