export const messages = {
  en: {
    translation: {
      nav: {
        home: "Home",
        about: "About Us",
        success: "Success Cases",
        requestDemo: "Request a Demo"
      },
      hero: {
        title: "Turn Your Automotive Agency into an AI-Powered Sales Machine",
        subtitle: "For dealership owners and managers who want to lead the market and sell more, with less effort",
        requestDemo: "Request a Demo"
      },
      features: {
        valueTitle: "Value for the Dealership",
        valueDescription: "Integrate your entire commercial system and maximize the efficiency of your teams with our solutions.",
        bullet1: "Integrate KarPilot with your BDC teams and agencies.",
        bullet2: "Connection with all industry CRMs, inventories, and acquisition channels.",
        bullet3: "Access to human advisor-client interaction through the original Chat.",
        bullet4: "Traceability of all lead interactions with AI, BDC advisors, and branch agents.",
        bullet5: "Funnel analytics, lead interests, and executive performance.",
        bullet6: "Mass campaigns for lead recovery, promotions, or after-sales service.",
        bullet7: "Personalized notifications to your BDC executives and/or Agency for immediate lead assignment.",
        secondTitle: "Process Optimization",
        secondDescription: "Automate and optimize lead and appointment management to increase your conversions.",
        secondBullet1: "Serve all your prospects 24/7 through our integration with all your acquisition channels.",
        secondBullet2: "Obtain formal appointments, assignments without an appointment, for quotations and pre-owned vehicles in a 100% automated manner.",
        secondBullet3: "Discard uncontactable and unqualified leads without wasting time interacting with them.",
        secondBullet4: "Provide appointment follow-ups, recover No-Shows, and request satisfaction evaluations from visitors.",
        thirdTitle: "Market Integrations",
        thirdDescription: "We make your life easier, we connect with the main industry players to maximize your opportunities and with any other system.",
        thirdBullet1: "Integration with leading CRMs like HubSpot and Salesforce.",
        thirdBullet2: "Connection with the main automotive portals.",
        thirdBullet3: "Ease of integration with inventory systems and digital marketing platforms.",
        thirdBullet4: "Workflow automation with key suppliers and distributors.",
        requestDemo: "Request a Demo"
      },
      trackRecord: {
        title: "Our Proven Track Record",
        subtitle: "We have a strong track record that speaks for itself.",
        metric1: "More than 1 million pre-qualified leads",
        metric2: "Over 50 automotive agencies trust us",
        metric3: "We triple dealership appointments"
      },
      form: {
        title: "Get Started",
        subtitle: "Accelerate your growth and boost your sales effortlessly.",
        nameLabel: "Name",
        namePlaceholder: "First and Last Name",
        emailLabel: "Your Work Email Address",
        emailPlaceholder: "name@company.com",
        phoneLabel: "Phone Number",
        phonePlaceholder: "Your phone number",
        sourceLabel: "How did you hear about us? (Optional)",
        sourcePlaceholder: "Let us know...",
        messageLabel: "Message",
        messagePlaceholder: "Your message...",
        submitButton: "Submit",
        disclaimer: "By submitting, you agree to receive emails from us. To learn how we handle your information, please see our privacy policy.",
        success: "Form successfully submitted!",
        error: "Error submitting form:",
        validation: "Please ensure the form is filled out correctly.",
        successMessage:"Our team will be in touch soon to help you conquer your goals. We're excited to start this adventure with you!",
        resetButton:"Start Over"
      },
      footer: {
        slogan: "Your Success, Our Commitment",
        resourcesTitle: "Resources",
        blog: "Blog",
        contact: "Contact",
        solutionsTitle: "Solutions",
        b2cSolution: "B2C Solution",
        b2bSolution: "B2B Solution",
        legalTitle: "Legal",
        privacyPolicy: "Privacy Policy"
      },
      aboutUs: {
        title: "About Us",
        intro: "We are a Chilean-Mexican company founded in Concepción, but with a global vision from day one. Our journey has been driven by the dedication of our founders, investors, and a committed team that makes Krino what it is today. We've also benefited from the support of various mentors and high-impact programs such as Peru Plug, Startup Chile, and New Chip in Austin, Texas. We move fast and always forward because we know the future doesn't wait.",
        foundersTitle: "Meet Our Founders",
        foundersText: "Víctor brings a unique track record that combines the dynamism of the startup world with the robustness of the corporate environment. He has led the creation of products from concept to becoming million-dollar successes. Javier, an internationally recognized entrepreneur, has leveraged his expertise in AI to attract early clients and drive significant technological advancements, fueling Krino's growth from the very beginning.",
        trajectoryTitle: "Our Journey",
        trajectoryText: "Since our start in 2019, we've iterated across different industries to find our product-market fit, which we discovered in the automotive and real estate sectors. To date, we've helped prequalify over 1 million leads, saving the industry and companies using our services more than 500,000 hours on average.",
        krinoTitle: "Who is Krino?",
        krinoText: "At Krino, our mission is clear: to break barriers and set new standards of innovation, delivering cutting-edge solutions that transform industries and create tangible impact. We aim to change the world by building a more agile market for companies worldwide, crafting tools that enable them to scale their operations through technology without needing to be experts. We know it's a long journey, and we're ready to walk it.",
        investorsTitle: "Our Investors",
        investorsText: "Our investors have played a crucial role in our journey. We wouldn't be the company we are today without their support. We've been backed by key players in the ecosystem, like Madesal Family Office, as well as two angel investors in the U.S. and Mexico. Additionally, we've secured over $500,000 USD in funding through grants and private investments from prestigious accelerators and investors, validating our vision and reinforcing our leadership position.",
        trustedByTitle: "Trusted By"
      },
      privacyPolicy: {
        title: "Privacy Policy of KarPilot",
        lastUpdated: "Last updated: 29/08/2024",
        section1: {
          title: "1. Introduction",
          text: "At KarPilot, operated by Krino Inc., we respect your privacy and are committed to protecting your personal data. This Privacy Policy explains how we collect, use, store, share, and protect your personal information when you visit our website or use our services."
        },
        section2: {
          title: "2. Information We Collect",
          text1: "We may collect and process the following types of personal data:",
          text2: "Information you provide to us: such as your name, email address, phone number, and any other information you provide through forms on our website.",
          text3: "Usage data: information about your browsing on our website, including your IP address, geographical location, browser type, referral source, visit duration, pages viewed, and navigation routes."
        },
        section3: {
          title: "3. How We Use Your Information",
          text: "We use the information we collect to..."
        },
        section4: {
          title: "4. Targeted Advertising",
          text: "We may partner with third parties, such as advertising networks and marketing partners, to display relevant ads to users of our website..."
        },
        section5: {
          title: "5. Sharing Your Information",
          text: "We do not share, sell, or rent your personal information to third parties, except in the following circumstances..."
        },
        section6: {
          title: "6. Security of Your Information",
          text: "We take technical and organizational security measures to protect your personal information against loss, misuse, and unauthorized access..."
        },
        section7: {
          title: "7. Your Rights",
          text: "Depending on your location, you may have the following rights regarding your personal data..."
        },
        section8: {
          title: "8. Data Retention",
          text: "We will retain your personal information only as long as necessary for the purposes outlined in this policy or as required by applicable law."
        },
        section9: {
          title: "9. Cookies and Similar Technologies",
          text: "We use cookies and similar technologies to improve the functionality of our website, personalize your experience, and conduct targeted advertising..."
        },
        section10: {
          title: "10. Links to Other Websites",
          text: "Our website may contain links to other websites. We are not responsible for the privacy practices of such sites..."
        },
        section11: {
          title: "11. Changes to This Policy",
          text: "We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new policy on this page..."
        },
        section12: {
          title: "12. Contact",
          text: "If you have any questions or concerns about this Privacy Policy, please contact us at: contacto@krino.cl."
        }
      },
      blog: {
        featuredArticles: "Featured Articles",
        workingOnCases: "We are currently working on documenting our success cases.",
        privacyNotice: "We are taking all necessary precautions to protect our clients' privacy.",
        privateDemos: "At this moment, we can only show them in private demos, but we will soon be publishing some of them."
      },
      calendly: {
        scheduleMeeting: "Schedule a Demo"
      }
    }
  },
  es: {
    translation: {
      nav: {
        home: "Inicio",
        about: "Sobre Nosotros",
        success: "Casos de Éxito",
        requestDemo: "Solicita una Demo"
      },
      hero: {
        title: "Convierte tu <strong>Agencia Automotriz</strong> en una <span style='color: #0099cc;'>Máquina de Ventas Automatizada con IA</span>",
        subtitle: "Para dueños y gerentes de automotoras que quieren <strong>liderar el mercado</strong> y vender más, con menos esfuerzo",
        requestDemo: "Solicita una Demo"
      },
      features: {
        valueTitle: "Valor para la Concesionaria",
        valueDescription: "Integra todo tu sistema comercial y maximiza la eficiencia de tus equipos con nuestras soluciones.",
        bullet1: "Integra a KarPilot con tus equipos de BDC y agencias.",
        bullet2: "Conexión con todos los CRM de la industria, inventarios y canales de adquisición.",
        bullet3: "Acceso a interacción humana asesor-cliente a través del Chat original.",
        bullet4: "Trazabilidad de todas las interacciones del lead con IA, asesores BDC y agentes de sucursal.",
        bullet5: "Analítica de funnel, intereses de leads y rendimiento de ejecutivos.",
        bullet6: "Campañas masivas para recuperación de leads, promociones o servicio post-venta.",
        bullet7: "Notifica de forma personalizada a tus ejecutivos BDC y/o de Agencia para asignación de leads inmediata.",
        secondTitle: "Optimización de Procesos",
        secondDescription: "Automatiza y optimiza el manejo de leads y citas para incrementar tus conversiones.",
        secondBullet1: "Atiende a todos tus prospectos 24/7 mediante nuestra integración a todos tus canales de adquisición.",
        secondBullet2: "Consigue citas formales, asignaciones sin cita, para cotización y semi-nuevos de forma 100% automatizada.",
        secondBullet3: "Descarta a los leads NO contactables y NO calificados sin perder tiempo interactuando con ellos.",
        secondBullet4: "Brinda seguimientos de citas, recupera No-Shows y solicita evaluaciones de satisfacción en visitantes.",
        thirdTitle: "Integraciones de Mercado",
        thirdDescription: "Te hacemos la vida más fácil, nos conectamos con los principales actores de la industria para maximizar tus oportunidades y con cualquier otro sistema.",
        thirdBullet1: "Integración con CRM líderes como HubSpot y Salesforce.",
        thirdBullet2: "Conexión con los principales portales automotrices.",
        thirdBullet3: "Facilidad de integración con sistemas de inventario y plataformas de marketing digital.",
        thirdBullet4: "Automatización del flujo de trabajo con proveedores y distribuidores clave.",
        requestDemo: "Solicita una Demo"
      },
      trackRecord: {
        title: "Nuestro Registro Comprobado",
        subtitle: "Tenemos un sólido historial que habla por sí mismo.",
        metric1: "Más de 1 millón de leads pre-calificados",
        metric2: "Más de 50 agencias automotrices confían en nosotros",
        metric3: "Triplicamos las citas en concesionarios"
      },
      form: {
        title: "Comienza Ahora",
        subtitle: "Acelera tu crecimiento y aumenta tus ventas sin esfuerzo.",
        nameLabel: "Nombre",
        namePlaceholder: "Nombre y Apellido",
        emailLabel: "Tu Dirección de Correo Electrónico de Trabajo",
        emailPlaceholder: "nombre@empresa.com",
        phoneLabel: "Número de Teléfono",
        phonePlaceholder: "Tu número de teléfono",
        sourceLabel: "¿Cómo te enteraste de nosotros? (Opcional)",
        sourcePlaceholder: "Haznos saber...",
        messageLabel: "Mensaje",
        messagePlaceholder: "Tu mensaje...",
        submitButton: "Enviar",
        disclaimer: "Al enviar, acepta recibir correos electrónicos de nosotros. Para saber cómo manejamos su información, consulte nuestra política de privacidad.",
        success: "¡Formulario enviado con éxito!",
        error: "Error al enviar el formulario:",
        validation: "Por favor, asegúrese de que el formulario esté correctamente completado.",
        successMessage:"Nuestro equipo se pondrá en contacto muy pronto para ayudarte a conquistar tus metas. ¡Estamos emocionados de comenzar esta aventura contigo!",
        resetButton:"Comenzar de nuevo"
      },
      footer: {
        slogan: "Tu Éxito, Nuestro Compromiso",
        resourcesTitle: "Recursos",
        blog: "Blog",
        contact: "Contacto",
        solutionsTitle: "Soluciones",
        b2cSolution: "Solución B2C",
        b2bSolution: "Solución B2B",
        legalTitle: "Legal",
        privacyPolicy: "Política de Privacidad"
      },
      aboutUs: {
        title: "Sobre Nosotros",
        intro: "Somos una compañía con raíces chileno-mexicanas, nacida en Concepción, con una ambición global desde el primer día. Nuestro camino ha sido posible gracias al esfuerzo conjunto de nuestros fundadores, inversionistas y un equipo comprometido que cada día convierte a Krino en lo que es hoy. Hemos contado con el valioso apoyo de mentores y programas de alto impacto como Perú Plug, Startup Chile y New Chip en Austin, Texas. Nos movemos rápido y siempre hacia adelante, porque sabemos que el futuro no espera.",
        foundersTitle: "Conoce a Nuestros Fundadores",
        foundersText: "Víctor encarna la perfecta fusión entre la agilidad del ecosistema startup y la solidez corporativa. Ha guiado la creación de productos desde la idea inicial hasta convertirlos en éxitos millonarios. Javier, un emprendedor de renombre internacional, ha utilizado su experiencia en IA para atraer a los primeros clientes y liderar importantes avances tecnológicos, impulsando el crecimiento de Krino desde sus inicios.",
        trajectoryTitle: "Nuestro Recorrido",
        trajectoryText: "Desde nuestro inicio en 2019, hemos iterado en diferentes industrias hasta encontrar nuestro product-market fit en los sectores automotriz e inmobiliario. A la fecha, hemos ayudado a precalificar más de 1 millón de leads, lo que ha supuesto un ahorro de más de 500,000 horas en promedio para la industria y para las empresas que usan nuestros servicios.",
        krinoTitle: "¿Quién es Krino?",
        krinoText: "En Krino, nuestra misión es clara: romper barreras y establecer nuevos estándares de innovación, entregando soluciones de vanguardia que transforman industrias y generan un impacto tangible. Queremos cambiar el mundo construyendo un mercado más ágil para las empresas de todo el mundo, creando herramientas que les permitan escalar sus operaciones a través de la tecnología sin necesidad de ser expertos. Sabemos que es un largo camino, y estamos dispuestos a recorrerlo.",
        investorsTitle: "Nuestros Inversionistas",
        investorsText: "Nuestros inversionistas han sido fundamentales en nuestro camino. No seríamos la compañía que somos hoy sin su apoyo. Hemos contado con el respaldo de actores clave del ecosistema, como Madesal Family Office, así como de dos ángeles inversores en EE.UU. y México. Además, hemos obtenido más de $500,000 USD en financiamiento entre subvenciones e inversiones privadas de prestigiosos aceleradores e inversores, lo que valida nuestra visión y refuerza nuestra posición de liderazgo.",
        trustedByTitle: "Confiado Por"
      },
      privacyPolicy: {
        title: "Política de Privacidad de KarPilot",
        lastUpdated: "Última actualización: 29/08/2024",
        section1: {
          title: "1. Introducción",
          text: "En KarPilot, operado por Krino Inc., respetamos su privacidad y estamos comprometidos a proteger sus datos personales. Esta Política de Privacidad explica cómo recopilamos, utilizamos, almacenamos, compartimos y protegemos su información personal cuando visita nuestro sitio web o utiliza nuestros servicios."
        },
        section2: {
          title: "2. Información que Recopilamos",
          text1: "Podemos recopilar y procesar los siguientes tipos de datos personales:",
          text2: "Información proporcionada por usted: como su nombre, dirección de correo electrónico, número de teléfono, y cualquier otra información que nos proporcione a través de formularios en nuestro sitio web.",
          text3: "Datos de uso: información sobre su navegación en nuestro sitio web, incluyendo su dirección IP, ubicación geográfica, tipo de navegador, fuente de referencia, duración de la visita, páginas vistas y rutas de navegación."
        },
        section3: {
          title: "3. Cómo Utilizamos su Información",
          text: "Utilizamos la información que recopilamos para..."
        },
        section4: {
          title: "4. Publicidad Dirigida",
          text: "Podemos colaborar con terceros, como redes publicitarias y socios de marketing, para mostrar anuncios relevantes a los usuarios de nuestro sitio web..."
        },
        section5: {
          title: "5. Compartir su Información",
          text: "No compartimos, vendemos ni alquilamos su información personal a terceros, salvo en las siguientes circunstancias..."
        },
        section6: {
          title: "6. Seguridad de su Información",
          text: "Tomamos medidas de seguridad técnicas y organizativas para proteger su información personal contra pérdida, uso indebido y acceso no autorizado..."
        },
        section7: {
          title: "7. Sus Derechos",
          text: "Dependiendo de su ubicación, puede tener los siguientes derechos con respecto a sus datos personales..."
        },
        section8: {
          title: "8. Retención de Datos",
          text: "Retendremos su información personal solo mientras sea necesario para los fines establecidos en esta política o según lo exija la ley aplicable."
        },
        section9: {
          title: "9. Cookies y Tecnologías Similares",
          text: "Utilizamos cookies y tecnologías similares para mejorar la funcionalidad de nuestro sitio web, personalizar su experiencia y realizar publicidad dirigida..."
        },
        section10: {
          title: "10. Enlaces a Otros Sitios Web",
          text: "Nuestro sitio web puede contener enlaces a otros sitios web. No somos responsables de las prácticas de privacidad de dichos sitios..."
        },
        section11: {
          title: "11. Cambios a Esta Política",
          text: "Podemos actualizar esta Política de Privacidad de vez en cuando. Le notificaremos cualquier cambio publicando la nueva política en esta página..."
        },
        section12: {
          title: "12. Contacto",
          text: "Si tiene alguna pregunta o inquietud sobre esta Política de Privacidad, por favor contáctenos en: contacto@krino.cl."
        }
      },
      blog: {
        featuredArticles: "Artículos Destacados",
        workingOnCases: "Actualmente estamos trabajando en la documentación de nuestros casos de éxito.",
        privacyNotice: "Estamos tomando todas las precauciones necesarias para proteger la privacidad de nuestros clientes.",
        privateDemos: "Por el momento, solo podemos mostrarlos en demostraciones privadas, pero pronto publicaremos algunos."
      },
      calendly: {
        scheduleMeeting: "Agenda una Demo"
      }
    }
  }
};
