import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import '../styles/Features.css';
import featureImage1 from '../assets/features/feature1.png';
import featureImage2 from '../assets/features/feature2.png';
import featureImage3 from '../assets/features/feature3.png';

function Features() {
  const { t } = useTranslation();

  useEffect(() => {
    const handleScroll = () => {
      const featureContainers = document.querySelectorAll('.feature-container');
      featureContainers.forEach(container => {
        const rect = container.getBoundingClientRect();
        if (rect.top <= window.innerHeight * 0.75) {
          container.classList.add('in-view');
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll();

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const featuresData = [
    {
      title: 'features.valueTitle',
      description: 'features.valueDescription',
      bullets: [
        'features.bullet1',
        'features.bullet2',
        'features.bullet3',
        'features.bullet4',
        'features.bullet5',
        'features.bullet6',
        'features.bullet7',
      ],
      image: featureImage1,
    },
    {
      title: 'features.secondTitle',
      description: 'features.secondDescription',
      bullets: [
        'features.secondBullet1',
        'features.secondBullet2',
        'features.secondBullet3',
        'features.secondBullet4',
      ],
      image: featureImage2,
    },
    {
      title: 'features.thirdTitle',
      description: 'features.thirdDescription',
      bullets: [
        'features.thirdBullet1',
        'features.thirdBullet2',
        'features.thirdBullet3',
        'features.thirdBullet4',
      ],
      image: featureImage3,
    },
  ];

  return (
    <div className="features">
      {featuresData.map((feature, index) => (
        <div key={index} className="feature-container">
          {/* Orden del texto e imagen corregido */}
          <div className="feature-text">
            <h2>{t(feature.title)}</h2>
            <p>{t(feature.description)}</p>
            <ul>
              {feature.bullets.map((bullet, i) => (
                <li key={i}>{t(bullet)}</li>
              ))}
            </ul>
            <Link to="/contact" className="cta-button">
              {t('features.requestDemo')}
            </Link>
          </div>
          <div className="feature-image-wrapper">
            <div className="feature-image">
              <img src={feature.image} alt={`Feature ${index + 1}`} />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Features;
