import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import logo from '../assets/Logo.png';
import usFlag from '../assets/us-flag.png';
import esFlag from '../assets/mx-flag.png';
import '../styles/Header.css';

function Header() {
  const { t, i18n } = useTranslation();
  const [showDropdown, setShowDropdown] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setShowDropdown(false);
  };

  const currentFlag = i18n.language === 'en' ? usFlag : esFlag;
  const otherLanguages = [
    { lng: 'en', flag: usFlag, label: 'English' },
    { lng: 'es', flag: esFlag, label: 'Español' },
  ].filter(language => language.lng !== i18n.language);

  return (
    <header>
      <nav>
        {/* Logo */}
        <div className="logo">
          <img src={logo} alt="CarPilot Logo" />
        </div>

        {/* Botón de Menú para Móviles */}
        <div className="mobile-menu-button" onClick={() => setIsMenuOpen(!isMenuOpen)}>
          ☰
        </div>

        {/* Links de Navegación */}
        <ul className={`nav-links ${isMenuOpen ? 'open' : ''}`}>
          <li>
            <Link to="/" onClick={() => setIsMenuOpen(false)}>{t('nav.home')}</Link>
          </li>
          <li>
            <Link to="/about" onClick={() => setIsMenuOpen(false)}>{t('nav.about')}</Link>
          </li>
          <li>
            <Link to="/blog" onClick={() => setIsMenuOpen(false)}>{t('nav.success')}</Link>
          </li>
        </ul>

        {/* Controles del Usuario */}
        <div className="right-section">
          {/* Botón de Demo para Escritorio */}
          <div className="demo-button">
            <Link to="/contact">{t('nav.requestDemo')}</Link>
          </div>

          {/* Selector de Idioma siempre visible */}
          <div className="language-selector" onClick={() => setShowDropdown(!showDropdown)}>
            <img src={currentFlag} alt="Language" className="flag-icon" />
            {showDropdown && (
              <ul className="dropdown-menu">
                {otherLanguages.map(language => (
                  <li key={language.lng} onClick={() => changeLanguage(language.lng)}>
                    <img src={language.flag} alt={language.label} /> {language.label}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Header;
