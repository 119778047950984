import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import '../styles/GetStarted.css';

function GetStartedForm() {
  const { t } = useTranslation();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [source, setSource] = useState('');
  const [message, setMessage] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateEmail(email)) {
      const form = e.target;
      const formData = new FormData(form);
      fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: new URLSearchParams(formData).toString(),
      })
        .then(() => {
          setFormSubmitted(true);
          clearFormFields();
        })
        .catch((error) => alert(t('form.error') + error));
    } else {
      alert(t('form.validation'));
    }
  };

  const validateEmail = (email) => {
    const workEmailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return workEmailPattern.test(email);
  };

  const clearFormFields = () => {
    setName('');
    setEmail('');
    setPhone('');
    setSource('');
    setMessage('');
  };

  const handleFormReset = () => {
    setFormSubmitted(false);
  };

  return (
    <div className="form-container">
      {formSubmitted ? (
        <div className="form-success-message">
          <div className="success-icon">&#10003;</div>
          <h2>{t('form.success')}</h2>
          <p>{t('form.successMessage')}</p>
          <button onClick={handleFormReset} className="submit-button">
            {t('form.resetButton')}
          </button>
        </div>
      ) : (
        <form
          name="get-started"
          method="POST"
          data-netlify="true"
          onSubmit={handleSubmit}
          className="get-started-form"
        >
          <input type="hidden" name="form-name" value="get-started" />

          <h2>{t('form.title')}</h2>
          <p className="form-subtitle">{t('form.subtitle')}</p>

          <div className="form-group">
            <label htmlFor="name">{t('form.nameLabel')}</label>
            <input
              type="text"
              name="name"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              placeholder={t('form.namePlaceholder')}
            />
          </div>

          <div className="form-group">
            <label htmlFor="email">{t('form.emailLabel')}</label>
            <input
              type="email"
              name="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
              placeholder={t('form.emailPlaceholder')}
            />
          </div>

          <div className="form-group">
            <label htmlFor="phone">{t('form.phoneLabel')}</label>
            <PhoneInput
              country={'us'}
              value={phone}
              onChange={(phone) => setPhone(phone)}
              inputProps={{
                name: 'phone',
                required: true,
                placeholder: t('form.phonePlaceholder'),
              }}
            />
          </div>

          <div className="form-group">
            <label htmlFor="source">{t('form.sourceLabel')}</label>
            <input
              type="text"
              name="source"
              id="source"
              value={source}
              onChange={(e) => setSource(e.target.value)}
              placeholder={t('form.sourcePlaceholder')}
            />
          </div>

          <div className="form-group">
            <label htmlFor="message">{t('form.messageLabel')}</label>
            <textarea
              name="message"
              id="message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder={t('form.messagePlaceholder')}
              rows="4"
              required
            />
          </div>

          <button type="submit" className="submit-button">
            {t('form.submitButton')}
          </button>

          <p className="form-disclaimer">{t('form.disclaimer')}</p>
        </form>
      )}
    </div>
  );
}

export default GetStartedForm;
