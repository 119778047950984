// src/components/ContactPage.js
import React from 'react';
import GetStartedForm from '../components/GetStarted';

function ContactPage() {
  return (
    <div className="contact-page">
      <h1>Contact Us</h1> {/* Puedes usar tu función de traducción aquí */}
      <GetStartedForm /> {/* Reutiliza el formulario aquí */}
    </div>
  );
}

export default ContactPage;
