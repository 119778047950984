import React from 'react';
import { useTranslation } from 'react-i18next';
import '../styles/AboutUs.css';
import cofoundersImage from '../assets/about/cofounders.png';
import trustedByImage1 from '../assets/about/empresas-madesal.png';
import trustedByImage2 from '../assets/about/startup-chile.png';
import trustedByImage3 from '../assets/about/endeavor.png';
import trustedByImage4 from '../assets/about/innovate-peru.png';
import trustedByImage5 from '../assets/about/corfo.png';

function AboutUs() {
  const { t } = useTranslation();

  return (
    <div className="about-us">
      <section className="about-us-intro">
        <div className="about-us-content">
          <h1>{t('aboutUs.title')}</h1>
          <p>{t('aboutUs.intro')}</p>
        </div>
      </section>

      <section className="about-us-founders">
        <div className="founders-container">
          <img src={cofoundersImage} alt="Cofounders" className="cofounders-image" />
          <div className="founders-content">
            <h2>{t('aboutUs.foundersTitle')}</h2>
            <p>{t('aboutUs.foundersText')}</p>
            <h2>{t('aboutUs.trajectoryTitle')}</h2>
            <p>{t('aboutUs.trajectoryText')}</p>
            <h2>{t('aboutUs.krinoTitle')}</h2>
        <p>{t('aboutUs.krinoText')}</p>
          </div>
        </div>
      </section>


      <section className="about-us-investors">
        <h2>{t('aboutUs.investorsTitle')}</h2>
        <p>{t('aboutUs.investorsText')}</p>
      </section>

      <section className="about-us-trusted-by">
        <h2>{t('aboutUs.trustedByTitle')}</h2>
        <div className="trusted-by-logos">
          <img src={trustedByImage1} alt="Corfo" />
          <img src={trustedByImage2} alt="Startup Chile" />
          <img src={trustedByImage3} alt="Endeavor" />
          <img src={trustedByImage4} alt="Innovate Peru" />
          <img src={trustedByImage5} alt="Startup BioBio" />
        </div>
      </section>
    </div>
  );
}

export default AboutUs;
