import React from 'react';
import { useTranslation } from 'react-i18next'; // Importa el hook de i18next
import '../styles/HeroSection.css';
import { Link } from 'react-router-dom';

function HeroSection() {
  const { t } = useTranslation(); // Hook para traducción

  return (
    <div className="hero-section">
      <div className="hero-content">
        <h1 dangerouslySetInnerHTML={{ __html: t('hero.title') }}></h1>
        <h4 dangerouslySetInnerHTML={{ __html: t('hero.subtitle') }}></h4>
        <Link to="/contact" className="cta-button">
        {t('hero.requestDemo')}
      </Link>
      </div>
    </div>
  );
}

export default HeroSection;
