import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CountUp from 'react-countup';
import '../styles/TrackRecord.css';
import logo from '../assets/Logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faCar, faCalendarCheck } from '@fortawesome/free-solid-svg-icons';

function TrackRecord() {
  const { t } = useTranslation();
  const [startCount, setStartCount] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const section = document.querySelector('.track-record');
      const rect = section.getBoundingClientRect();
      if (rect.top <= window.innerHeight * 0.75) {
        setStartCount(true);
        window.removeEventListener('scroll', handleScroll); // Desactiva el listener después de activarse
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className="track-record">
      <div className="track-record-header">
        <h2>{t('trackRecord.title')}</h2>
        <img src={logo} alt="Company Logo" className="company-logo" />
      </div>
      <p className="track-record-subtitle">{t('trackRecord.subtitle')}</p>
      <div className="metrics">
        <div className="metric">
          <FontAwesomeIcon icon={faUsers} className="metric-icon" />
          <h3>
            {startCount ? <CountUp end={1000000} duration={3} separator="," /> : '0'}+
          </h3>
          <p>{t('trackRecord.metric1')}</p>
        </div>
        <div className="metric">
          <FontAwesomeIcon icon={faCar} className="metric-icon" />
          <h3>
            {startCount ? <CountUp end={50} duration={3} /> : '0'}+
          </h3>
          <p>{t('trackRecord.metric2')}</p>
        </div>
        <div className="metric">
          <FontAwesomeIcon icon={faCalendarCheck} className="metric-icon" />
          <h3>
            {startCount ? <CountUp end={3} duration={3} /> : '0'}x
          </h3>
          <p>{t('trackRecord.metric3')}</p>
        </div>
      </div>
    </div>
  );
}

export default TrackRecord;
